<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @create="create" @edit="edit"/>
      </v-col>
    </v-row>
    <create-field modal_name="create_field" ref="create_field" @refresh="reload"/>
    <edit-field modal_name="edit_field" ref="edit_field" @refresh="reload"/>
  </v-container>
</template>

<script>
import CreateField from "./Create";
import EditField from "./Edit";
import BaseTable from "../../components/commonComponents/BaseTable";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
export default {
  components: {
    BaseTable,
    BaseBreadcrumb,
    CreateField,
    EditField,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("fields"),
        name: "fields",
        model: "field",
      },
    };
  },
  methods: {
    create(selected_model) {
      this.$refs.create_field.load(selected_model);
    },
    edit(item) {
      this.$refs.edit_field.load(item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
  },
};
</script>