<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <v-text-field class="mt-5" :label="$store.getters.translate('name')" v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-select :label="$store.getters.translate('field_type')" v-model="record.field_type" :items="field_types">
              <template #label>{{ $store.getters.translate('field_type') }}<span class="red--text"><strong>*</strong></span></template>
            </v-select>
            <v-combobox
                v-if="record.field_type === 'select_single' || record.field_type === 'select_multiple'"
                :label="$store.getters.translate('list_options')"
                chips
                deletable-chips
                small-chips
                clearable
                multiple
                v-model="record.available_options"/>
            <v-switch :label="$store.getters.translate('mandatory')" v-model="record.mandatory"/>
            <v-switch :label="$store.getters.translate('show_in_search')" v-model="record.show_in_search"/>
            <v-switch :label="$store.getters.translate('show_on_creation')" v-model="record.show_on_creation"/>
            <v-switch :label="$store.getters.translate('show_on_update')" v-model="record.show_on_update"/>
            <v-switch :label="$store.getters.translate('use_in_import')" v-model="record.use_in_import"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("field"),
        name: "fields",
        model: "field",
      },
      loading: false,
      record: {},
      field_types: [],
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(selected_model) {
      this.record = {
        model_type: selected_model,
      };
      this.field_types = this.$lodash.sortBy(helpFunctions.field_types, 'text');
      this.openModal(this.modal_name);
    },
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}, {name: 'field_type', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, usual_fields_required)) {
          this.loading = true;
          //create translation
          let key = this.$lodash.lowerCase(this.record.name).replace(/ /g, "_");
          await helpFunctions.createModel(this.$toasted, 'translations', { key: key, language: 'en', value: this.record.name, deletable: true });
          await this.$store.dispatch("refreshTranslations");
          //create field
          this.record.name = key;
          let id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
          if(id) {
            this.$emit("refresh");
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
  },
};
</script>